import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Blog from "../components/Blog";
import Seo from "../components/Seo";

const BlogListPage = props => {
  const {
    data: {
      posts: { edges: posts = [] },
      site: {
        siteMetadata: { facebook }
      }
    }
  } = props;

  return (
    <React.Fragment>
      <ThemeContext.Consumer>{theme => <Blog posts={posts} theme={theme} />}</ThemeContext.Consumer>
      <Seo facebook={facebook} />
    </React.Fragment>
  );
};

BlogListPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default BlogListPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query GetTenPosts {
    posts: allPrismicGenericPost(sort: { fields: first_publication_date, order: DESC }, limit: 30) {
      edges {
        node {
          id
          first_publication_date
          last_publication_date
          slugs
          uid
          tags
          data {
            author_link {
              id
              slug
              document {
                data {
                  author_name {
                    html
                    text
                  }
                }
              }
            }
            title {
              html
              text
            }
            content {
              html
              text
              raw {
                type
                text
              }
            }
            thumbnail_image {
              url
              alt
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;
